import {graphql} from "gatsby";
import Banner from "../components/Banner";
import Layout from "../components/Layout";
import React from "react";
import {NewsItem} from "../components/News";

const AllNews = ({data}) => {
    const articles = data.news.edges.map(e=>e.node)

    return (
        <Layout title={"Newsroom"}>
            <Banner heightSmall={true} backgroundImage={'/media/mv-01.jpg'}>
                <div className={"banner-title"}>
                    <span style={{fontSize: "3rem", fontWeight: "bold"}}>Newsroom</span>
                </div>
            </Banner>
            <article className="section">
                <div className={"main-grid"}>
                    {articles.map(item => {
                        return <div style={{background: "#e9eff4"}}>
                            <NewsItem data={item}/>
                        </div>
                    })}
                </div>
            </article>
        </Layout>
    )
}

export default AllNews

export const query = graphql`
query {
  news: allSanityNews(sort: {order: DESC, fields: _createdAt}) {
    edges {
      node {
        title
        tag
        id
        _rawArticle
        _createdAt
                image {
              ...ImageWithPreview
            }
      }
    }
  }
}
`
